import { ref } from 'vue'
import { defineStore } from 'pinia'
export const slideStore = defineStore('slide', () => {
  const slideFullWidth = 200
  const slideFoldWidth = 50
  const isFoldSlide = ref(false) //false:不折叠  true:折叠
  const isHideSlide = ref(false) //false:显示  true:隐藏
  const isHideDrawer = ref(false) // false:隐藏 true:显示

  const setIsFoldSlide = (b: boolean) => {
    isFoldSlide.value = b
  }
  const setIsHideSlide = (b: boolean) => {
    isHideSlide.value = b
  }
  const setIsHideDrawer = (b: boolean) => {
    isHideDrawer.value = b
  }
  return {
    isFoldSlide,
    setIsFoldSlide,
    isHideSlide,
    setIsHideSlide,
    slideFullWidth,
    slideFoldWidth,
    setIsHideDrawer,
    isHideDrawer
  }
})
