/**
 * @function 导出blob格式的方法
 *  @params
 *    data： BlobPart   ->   二进制流
 *    fileName： string   ->  文件名
 *
 */
export const exportFile = (data: BlobPart, fileName?: string) => {
  if (!fileName) {
    fileName = '导出文件.xlsx'
  }
  const url = window.URL.createObjectURL(new Blob([data]))
  const aTags: HTMLAnchorElement = document.createElement('a')
  aTags.style.display = 'none'
  aTags.href = url
  aTags.setAttribute('download', fileName)
  document.body.appendChild(aTags)
  aTags.click()
  document.body.removeChild(aTags)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExistFilter = (obj: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const returnObj: any = {}
  for (const key in obj) {
    if (!obj[key]) {
      delete obj[key]
    } else {
      const str = encodeURIComponent(key)
      returnObj[str] = obj[key]
    }
  }
  return returnObj
}

/**
 * 参数处理
 * @param {*} params  参数
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tansParams(params: { [x: string]: any }) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    const part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
            const params = propName + '[' + key + ']'
            const subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}
