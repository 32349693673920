import instance from '@/plugins/request'
import { ResponseData } from '@/interface/request'
export const getMineInfo = (): Promise<ResponseData> => {
  return instance.request({
    method: 'get',
    url: `/system/user/getPersonalCenterData`
  })
}
export const updateMineInfo = (data: unknown): Promise<ResponseData> => {
  return instance.request({
    method: 'put',
    url: `/system/user `,
    data
  })
}
export const updateMinePwd = (data: unknown): Promise<ResponseData> => {
  return instance.request({
    method: 'put',
    url: `/system/user/profile/updatePwd`,
    data
  })
}

// 更新头像
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUserPhoto = (data: unknown, url?: unknown): any => {
  return instance.request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    url: `/system/user/profile/avatar?avatar=${url}`,
    data
  })
}
// 编辑用户信息
export const editUserMsg = (data: unknown): Promise<ResponseData> => {
  return instance.request({
    method: 'put',
    url: `/system/user`,
    data
  })
}
// 获取个人中心
export const getusermsg = (): Promise<ResponseData> => {
  return instance.request({
    method: 'get',
    url: `/system/user/`
  })
}
// 重置密码
export const resetpwd = (data: unknown): Promise<ResponseData> => {
  return instance.request({
    method: 'put',
    url: `/system/user/resetPwd`,
    data
  })
}
