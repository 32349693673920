import NProgress from 'nprogress'
import router from '@/router'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { getSession, getToken, removeToken, setSession } from '@/utils/auth'
import { userInfoStore } from '@/store/userInfo'
import { permissionStore } from '@/store/permission'
import { ResponseData } from '@/interface/request'
import { authPermi, authRole } from '@/utils/hasAuth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/', '/login', '/auth-redirect', '/401', '/404', '/register']

router.afterEach((to) => {
  console.log(router.getRoutes())

  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title || window.VITE_APP_TITLE || import.meta.env.VITE_APP_TITLE
  }
  NProgress.done()
})
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta && to.meta.title) {
    // console.log(to, 'to')
    const routerArr = JSON.parse(getSession('routerList')) ? JSON.parse(getSession('routerList')) : []
    const routerObj = {
      name: to.meta.title,
      path: to.fullPath
    }
    const Arr = []
    if (routerArr && routerArr.length) {
      for (let i = 0; i < routerArr.length; i++) {
        Arr.push(routerArr[i].name)
      }
    }
    // console.log(routerArr, 'Arr')
    if (!Arr.includes(to.meta.title)) {
      routerArr.push(routerObj)
      setSession('routerList', JSON.stringify(routerArr))
    } else {
      if (to.fullPath.includes('?')) {
        for (let i = 0; i < routerArr.length; i++) {
          if (routerArr[i].name == to.meta.title) {
            routerArr[i].path = to.fullPath
          }
        }
        setSession('routerList', JSON.stringify(routerArr))
      }
    }
    if (routerArr[0].name != '工作台') {
      routerArr.unshift({
        name: '工作台',
        path: '/index'
      })
      setSession('routerList', JSON.stringify(routerArr))
    }
  }
  NProgress.start()
  if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/index' })
      NProgress.done()
    } else {
      if (whiteList.includes(to.path)) {
        next()
        NProgress.done()
      } else {
        const userInfo = userInfoStore()
        if (userInfo.userInfo) {
          next()
        } else {
          userInfo
            .getUserInfo()
            .then((info: ResponseData) => {
              permissionStore()
                .generateRoutes()
                .then((result) => {
                  if (info.permissions) {
                    authPermi('*:*:*', info.permissions)
                  }
                  if (info.roles) {
                    authRole('admin', info.roles)
                  }

                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  result.forEach((element: any) => {
                    if (element.path && !element.path.includes('http')) {
                      router.addRoute(element)
                    }
                  })
                  next({ ...to, replace: true })
                  NProgress.done()
                })
                .catch((err) => {
                  console.error(err, '获取路由信息报错')
                  userInfo
                    .loginOut()
                    .then(() => {
                      removeToken()
                      if (to.query && to.query.redirect) {
                        next({
                          path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                          query: {
                            redirect: to.query.redirect
                          }
                        })
                      } else {
                        next({
                          path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                          query: {
                            redirect: to.fullPath
                          }
                        })
                      }
                    })
                    .catch(() => {
                      next({
                        path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                        query: {
                          redirect: to.fullPath
                        }
                      })
                    })
                })
            })
            .catch((err) => {
              console.error(err, '获取用户信息报错')
              userInfo
                .loginOut()
                .then(() => {
                  if (to.query && to.query.redirect) {
                    next({
                      path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                      query: {
                        redirect: to.query.redirect
                      }
                    })
                  } else {
                    next({
                      path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                      query: {
                        redirect: to.fullPath
                      }
                    })
                  }
                })
                .catch(() => {
                  removeToken()
                  next({
                    path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
                    query: {
                      redirect: to.fullPath
                    }
                  })
                })
            })
        }
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next({
        path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE,
        query: {
          redirect: to.fullPath
        }
      })
      NProgress.done()
    }
  }
})
