<template>
  <div
    :style="{ width: routerList.length * 110 + 'px' }"
    class="flex flex-nowrap py-[2px] bg-white border-bg-light tabbar">
    <div
      class="itemtag"
      v-for="(item, index) of routerList"
      :key="index"
      @click.stop="tagClick(item)"
      @contextmenu.prevent="openMenu($event, item)">
      <div
        class="bg-bg-normal hover:bg-bg-light flex flex-nowrap py-xxs px-xs mr-xs rounded-xs cursor-pointer"
        style="white-space: nowrap">
        <div
          class="leading-[13px] text-14"
          :class="route.path == filter(item.path) ? 'text-primary-normal' : 'text-word-subtitle'">
          {{ item.name }}
        </div>
        <iconfont
          v-if="index !== 0"
          name="icon-icon_close"
          class="text-14 .icon ml-xs"
          :class="route.path == filter(item.path) ? 'text-primary-normal' : 'text-word-subtitle'"
          @click.stop="tagClose(item)"></iconfont>
      </div>
    </div>

    <div
      v-if="isMore"
      class="rounded-sm p-xs h-[130px] fixed top-[46px] z-[2147483647] bg-white"
      style="box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%)"
      :style="{ left: leftPx + 'px' }">
      <div
        v-if="menuName != '工作台'"
        class="p-xs hover:bg-bg-hover cursor-pointer text-14"
        @click="closeCurrent"
        @contextmenu.prevent>
        关闭当前标签
      </div>
      <div
        class="p-xs hover:bg-bg-hover cursor-pointer text-14"
        @click="closeOther"
        @contextmenu.prevent>
        关闭其他标签
      </div>
      <div
        class="p-xs hover:bg-bg-hover cursor-pointer text-14"
        @click="closeAll"
        @contextmenu.prevent>
        关闭全部标签
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getSession, setSession } from '@/utils/auth'
import { onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
interface routerObj {
  name: string
  path: string
}
const route = useRoute()
const router = useRouter()
const routerList = ref<Array<routerObj>>([])
const timer = ref()
const isMore = ref<boolean>(false)
const leftPx = ref<number>(216)
const menuName = ref<string>('')
const bodyWidth = ref<number>(1800)
const emits = defineEmits(['tagTotalWidth'])
const init = () => {
  routerList.value = JSON.parse(getSession('routerList')) ? JSON.parse(getSession('routerList')) : []
}

const filter = (path: string) => {
  if (path.includes('?')) {
    const index = path.indexOf('?')
    return path.substring(0, index)
  } else {
    return path
  }
}
const closewatch = watch(
  () => route.path,
  () => {
    init()
    emits('tagTotalWidth', routerList.value.length * 100)
  },
  {
    immediate: true,
    deep: true
  }
)

const tagClick = (val: routerObj) => {
  userClick()
  if (val.path.includes('?')) {
    router.replace(val.path)
  } else {
    router.replace({
      path: val.path
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const openMenu = (event: any, val: any) => {
  if (bodyWidth.value - event.pageX < 100) {
    leftPx.value = event.pageX - 120
  } else {
    leftPx.value = event.pageX - 50
  }
  menuName.value = ''
  menuName.value = val.name
  isMore.value = true
}

const closeCurrent = () => {
  if (menuName.value == route.meta.title) {
    // console.log('关闭当前')
    let idx = 0
    for (let i = 0; i < routerList.value.length; i++) {
      if (routerList.value[i].name == menuName.value) {
        // console.log(routerList.value[i],val,'val');
        if (i == routerList.value.length - 1) {
          // console.log('等于最后一个')
          idx = i - 1
        } else {
          // console.log('不等于最后一个')
          idx = i
        }
        // console.log(idx, routerList.value.length - 1, 'idc')
        routerList.value.splice(i, 1)
        setSession('routerList', JSON.stringify(routerList.value))
      }
    }
    init()
    timer.value = setTimeout(() => {
      if (route.meta.title == menuName.value) {
        router.replace(routerList.value[idx].path)
      }
    }, 50)
  } else {
    // console.log('关闭非当前');
    for (let i = 0; i < routerList.value.length; i++) {
      if (routerList.value[i].name == menuName.value) {
        routerList.value.splice(i, 1)
        setSession('routerList', JSON.stringify(routerList.value))
      }
    }
  }
  emits('tagTotalWidth', routerList.value.length * 100)
}
const closeOther = () => {
  let newList = routerList.value.filter((item) => item.name == menuName.value || item.path == '/index')
  setSession('routerList', JSON.stringify(newList))
  init()
  timer.value = setTimeout(() => {
    router.replace(routerList.value[routerList.value.length - 1].path)
  }, 10)
  emits('tagTotalWidth', routerList.value.length * 100)
}
const closeAll = () => {
  let newList = routerList.value.filter((item) => item.path == '/index')
  setSession('routerList', JSON.stringify(newList))
  init()
  timer.value = setTimeout(() => {
    router.replace(routerList.value[routerList.value.length - 1].path)
  }, 10)
  emits('tagTotalWidth', routerList.value.length * 100)
}
const tagClose = (val: routerObj) => {
  emits('tagTotalWidth', routerList.value.length * 100)
  // console.log(val.name , route.meta.title,'我看');
  if (val.name == route.meta.title) {
    // console.log('关闭当前')
    let idx = 0
    for (let i = 0; i < routerList.value.length; i++) {
      if (routerList.value[i].name == val.name) {
        // console.log(routerList.value[i], val, 'val')
        if (i == routerList.value.length - 1) {
          // console.log('等于最后一个')
          idx = i - 1
        } else {
          // console.log('不等于最后一个')
          idx = i
        }
        // console.log(idx, routerList.value.length - 1, 'idc')
        routerList.value.splice(i, 1)
        setSession('routerList', JSON.stringify(routerList.value))
      }
    }
    init()
    timer.value = setTimeout(() => {
      if (route.meta.title == val.name) {
        router.replace(routerList.value[idx].path)
      }
    }, 50)
  } else {
    // console.log('关闭非当前');

    for (let i = 0; i < routerList.value.length; i++) {
      if (routerList.value[i].name == val.name) {
        routerList.value.splice(i, 1)
        setSession('routerList', JSON.stringify(routerList.value))
      }
    }
  }
}
const userClick = () => {
  emits('tagTotalWidth', routerList.value.length * 100)
  isMore.value = false
}

window.addEventListener('click', userClick)
bodyWidth.value = document.body.scrollWidth
onUnmounted(() => {
  closewatch()
  clearTimeout(timer.value)
})
init()
</script>
<style lang="less">
.tabbar {
  .itemtag {
    &:hover div,
    &:hover .icon {
      color: var(--primary-color);
    }
  }
}
</style>
