<template><router-view /></template>

<script setup lang="ts">
import config from '@/static/color.json'
document.documentElement.style.setProperty('--primary-color', config.primary.normal)
document.documentElement.style.setProperty('--primary-dark-color', config.primary.click)
document.documentElement.style.setProperty('--border-color', config.border.normal)
document.documentElement.style.setProperty('--font-color', config.word.title)
document.documentElement.style.setProperty('--bg-color', config.bg.normal)
document.documentElement.style.setProperty('--placeholder-color', config.border.stroke)
window.addEventListener(
  'error',
  (event) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let target: any = event.target
    let isElementTarget = target instanceof HTMLScriptElement || target instanceof HTMLLinkElement || target instanceof HTMLImageElement
    if (!isElementTarget) return false
    let url = target.src || target.href
    console.log(url + '资源加载错误')
  },
  true
)
</script>
