import hasPermi from './component/has-permi'
import hasRole from './component/has-role'
import hasDisabled from './component/has-permi-disabled'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function directive(app: { directive: (arg0: string, arg1: { mounted(el: HTMLElement, binding: { value: any }): void }) => void }) {
  app.directive('hasPermi', hasPermi)
  app.directive('hasRole', hasRole)
  app.directive('hasDisabled', hasDisabled)
}
