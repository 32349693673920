import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Message, Notification } from '@arco-design/web-vue'
import { getToken, removeToken } from '@/utils/auth'
import { tansParams } from '@/utils/methods'

const TIMEOUT = 60000
class Request {
  interceptors(instance: AxiosInstance) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    instance.interceptors.request.use((req: any) => {
      return req
    })
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        if (res.status === 200) {
          if (
            res.data.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            res.data.type == 'application/json' ||
            res.data.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
          ) {
            return res.data
          }
          if (res.data.code === 200) {
            return res.data
          } else {
            if (res.data.code === 401) {
              Message.warning('登录信息已过期,请重新登录')
              removeToken()
              window.location.href = '#' + (window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE)
              return res.data
            } else if (res.data.code === 404) {
              window.location.href = '/#/404'
            } else {
              // 网络状态没问题，后台接口状态没问题，后台返回状态码不是200
              return res.data
            }
            return
          }
        } else {
          // 网络状态没问题，后台接口状态不对
          Notification.error(res.statusText)
        }
      },
      (error) => {
        let { message } = error
        if (message == 'Network Error') {
          message = '连接异常，请联系管理员处理，错误码:[Network Error]'
        } else if (message.includes('timeout')) {
          message = '连接超时，请稍后尝试'
        } else if (message.includes('Request failed with status code')) {
          message = '未知异常，请联系管理员处理，错误码' + message.substr(message.length - 3)
        }
        Notification.error({
          content: message
        })
        return Promise.reject(error)
      }
    )
  }
  getConfig(config: AxiosRequestConfig): AxiosRequestConfig {
    const base: AxiosRequestConfig = {
      baseURL: window.VITE_APP_BASE_API || import.meta.env.VITE_APP_BASE_API,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: getToken()
      },
      timeout: TIMEOUT
    }
    // get请求映射params参数
    if ((config.method === 'get' || config.method === 'delete') && config.params) {
      let url = config.url + '?' + tansParams(config.params)

      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    if (config.url && /\/login\/?$/.test(config.url) && base.headers && base.headers['Authorization']) {
      delete base.headers['Authorization']
      return base
    }

    return base
  }
  request(config: AxiosRequestConfig): Promise<AxiosResponse> {
    const instance = axios.create(this.getConfig(config))
    this.interceptors(instance)
    return instance.request(config)
  }
}

const instance = new Request()

export default instance
