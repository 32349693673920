import iconfont from './iconfont.vue'

const iconfontPlugin = {
  name: 'iconfont',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install(app: any): void {
    // 全局挂载
    app.component('iconfont', iconfont)
  }
}

export default iconfontPlugin
