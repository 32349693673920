import Cookies from 'js-cookie'

const token = 'Admin-token'

export const getToken = () => {
  return Cookies.get(token)
}

export const setToken = (str: string) => {
  return Cookies.set(token, str)
}

export const removeToken = () => {
  return Cookies.remove(token)
}

export const getCookie = (key: string) => {
  return Cookies.get(key)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = (key: string, val: any) => {
  return Cookies.set(key, val)
}

export const removeCookie = (key: string) => {
  return Cookies.remove(key)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStorage = (key: string): any => {
  return localStorage.getItem(key)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setStorage = (key: string, val: any) => {
  return localStorage.setItem(key, val)
}

export const removeStorage = (key: string) => {
  return localStorage.removeItem(key)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSession = (key: string): any => {
  return sessionStorage.getItem(key)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setSession = (key: string, val: any) => {
  return sessionStorage.setItem(key, val)
}

export const removeSession = (key: string) => {
  return sessionStorage.removeItem(key)
}
