<template>
  <a-drawer
    v-if="isHideSlide"
    class="slide-drawer"
    :width="slideStore().slideFullWidth"
    height="100vh"
    :drawer-style="{
      overflow: 'visible'
    }"
    :visible="isHideDrawer"
    :footer="false"
    :header="false"
    @cancel="slideStore().setIsHideDrawer(!isHideDrawer)"
    :placement="'left'"
    mask
    mask-closable
    unmountOnClose>
    <a-scrollbar class="overflow-auto h-[100vh] w-full">
      <div class="h-full box-border px-[8px]">
        <div class="py-sm flex justify-center items-center">
          <img
            @click="$router.push({ path: '/index' })"
            class="w-[152px] h-[30px]"
            v-if="isHideSlide"
            :src="IV" />
          <div
            v-else
            class="p-xxs rounded-full flex justify-center items-center">
            <img
              class="w-[28px]"
              :src="IVHIDE" />
          </div>
        </div>

        <a-menu
          @menu-item-click="linkpage"
          :selected-keys="defaultKeys"
          :default-open-keys="openKeys"
          breakpoint="xl"
          :collapsed="false"
          auto-scroll-into-view
          :popup-max-height="500"
          v-if="sidebarRoute"
          :theme="setting.theme">
          <SlideItem
            :sidebarRoute="sidebarRoute"
            :parentPath="[]"></SlideItem>
        </a-menu>
      </div>
    </a-scrollbar>
  </a-drawer>
  <div
    v-else
    class="slide box-border px-[4px] z-30 h-full relative ease-linear"
    :style="{ width: isHideSlide ? '100vw' : isFold ? slideStore().slideFoldWidth + 'px' : slideStore().slideFullWidth + 'px' }">
    <div class="py-sm flex justify-center items-center">
      <div
        v-if="isFold"
        @click="$router.push({ path: '/index' })"
        class="p-xxs rounded-full flex justify-center items-center">
        <img
          class="w-[28px]"
          :src="IVHIDE"
          alt="logo图标" />
      </div>
      <img
        class="h-[30px]"
        v-else
        :src="IV"
        alt="完整logo" />
    </div>
    <a-scrollbar
      class="overflow-auto"
      :style="{
        height: 'calc(100vh - 96px)'
      }">
      <a-menu
        @menu-item-click="linkpage"
        :selected-keys="defaultKeys"
        :default-open-keys="openKeys"
        breakpoint="xl"
        :collapsed="isFold"
        :collapsed-width="slideStore().slideFoldWidth - 8"
        selectable
        v-if="sidebarRoute"
        :theme="setting.theme"
        auto-scroll-into-view
        :popup-max-height="500">
        <SlideItem
          :sidebarRoute="sidebarRoute"
          :parentPath="[]"></SlideItem>
      </a-menu>
    </a-scrollbar>
    <div
      class="flex flex-row p-sm"
      :class="[isFold ? 'justify-center' : 'justify-end']">
      <i
        @click="slideStore().setIsFoldSlide(!isFold)"
        class="iconfont icon-caidanzhedie"
        :class="[isFold ? 'active' : '']"></i>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from 'vue'
import IV from '@/assets/images/logo_word.png'
import IVHIDE from '@/assets/video-ico.svg'
import SlideItem from './slide-item.vue'

import { permissionStore } from '@/store/permission'
import { settingStore } from '@/store/setting'
import { useRoute, useRouter } from 'vue-router'
import { routerData } from '@/interface/router'
import { slideStore } from '@/store/slide'
const route = useRoute()
const router = useRouter()
const setting = settingStore()
let isFold = computed(() => {
  return slideStore().isFoldSlide
})
let isHideSlide = computed(() => {
  return slideStore().isHideSlide
})
let isHideDrawer = computed(() => {
  return slideStore().isHideDrawer
})
let defaultKeys = ref<Array<string>>([])

/**
 * @vairable
 *  sidebarRoute 页面路由数据
 *  expandRoutes 配置项中展开显示的路由
 *  fastEntry    配置项中让工作台显示的路由
 *  openKeys     当前页面限制展开的路由
 * */

let sidebarRoute = computed(() => {
  let arr: Array<routerData> = []
  permissionStore().routes.forEach((el: routerData) => {
    if (!el.meta && el.children && el.path === '/' && !el.hidden) {
      arr.push(...el.children)
    } else {
      arr.push(el)
    }
  })

  return arr
})

let expandRoutes = computed(() => {
  return permissionStore().expandRoutes
})
watch(
  [() => route.path, () => route.meta.activeMenu],

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ([v1, v2]: any) => {
    if (v2) {
      defaultKeys.value = [v2]
    } else {
      defaultKeys.value = [v1]
    }
  },
  {
    immediate: true
  }
)

let openKeys = computed(() => {
  // let str = route.path.split('/')
  const strArr: Array<string> = []
  // str.reduce((pre, next, currentIndex) => {
  //   if (next) {
  //     if (currentIndex > 0 && pre) {
  //       strArr.push(pre + '/' + next)
  //       return pre + '/' + next
  //     } else {
  //       strArr.push('/' + next)
  //       return '/' + next
  //     }
  //   }
  // }, '')
  if (expandRoutes.value && expandRoutes.value.length) {
    strArr.push(...expandRoutes.value)
  }
  return strArr
})

const linkpage = (key: string) => {
  if (key && key !== '/passenger') {
    router.push({ path: key })
  } else {
    window.open(window.location.origin + '/vision/#/index', '客情分析')
  }
}
</script>
<style lang="less" scoped>
.slide-drawer {
  .arco-drawer-body {
    padding: 0;
  }
}

.icon-caidanzhedie {
  display: inline-block;
}

.icon-caidanzhedie.active {
  transform: rotateY(180deg);
}

.slide {
  box-shadow: 0 4px 10px 0 rgb(78 89 105 / 6%);
}
</style>
<style lang="less">
.slide {
  .arco-scrollbar-track {
    display: none !important;
  }
}
</style>
