import Layouts from '@/layouts/layout.vue'
export default [
  {
    path: '/',
    redirect: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/401',
    component: () => import('@/views/error/401.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue')
  },
  {
    path: '/',
    redirect: '/index',
    component: Layouts,
    children: [
      {
        path: '/index',
        component: () => import('@/views/index/index.vue'),
        name: 'Index',
        meta: { title: '工作台', icon: 's-home', affix: true }
      }
    ]
  },
  {
    path: '/userInfo',
    component: Layouts,
    children: [
      {
        path: '/userInfo/person',
        component: () => import('@/views/userInfo/index.vue'),
        meta: { title: '个人中心', icon: 's-home', affix: true }
      }
    ]
  },
  {
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/redirect.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/404.vue')
  }
]
