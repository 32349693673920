import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getInfo, logout } from '@/api/login'
import { removeToken } from '@/utils/auth'
import defAva from '@/assets/icons/avatar.svg'
import { ResponseData } from '@/interface/userInfo'
import { getusermsg } from '@/api/mineInfo'

export const userInfoStore = defineStore('userInfo', () => {
  const userInfo = ref()
  const permissions = ref()
  const roles = ref()
  const avatar = ref(defAva)
  const isEditUser = ref()
  const jobIdArr = ref<Array<{ userId: string | undefined; jobId: string }>>([])
  const empowerJobArr = ref<{ [x: string]: string | boolean }[]>([])
  const getUserInfo = (): Promise<ResponseData> => {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(async (res: ResponseData) => {
          if (res.code === 200) {
            const res2 = await getusermsg()
            res.user = res2.data
            // console.log( res.user,' res.user');

            userInfo.value = null
            userInfo.value = res.user
            roles.value = res.roles
            permissions.value = res.permissions
            isEditUser.value = res.isEditUser
            if (res.user) {
              avatar.value = res.user.avatar ? res.user.avatar : defAva
            }
            resolve(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const loginOut = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          userInfo.value = ''
          roles.value = []
          permissions.value = []
          removeToken()
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  const handleJudgeAuth = (arr: Array<string>): boolean => {
    let flag = false
    const all_permission = '*:*:*'
    if (arr && arr instanceof Array && arr.length > 0) {
      const permissionFlag = arr
      const hasPermissions = permissions.value.some((permission: string) => {
        return all_permission === permission || permissionFlag.includes(permission)
      })
      flag = hasPermissions
    } else {
      throw new Error(`请设置操作权限标签值`)
    }
    return flag
  }
  return {
    userInfo,
    avatar,
    permissions,
    roles,
    isEditUser,
    jobIdArr,
    empowerJobArr,
    getUserInfo,
    loginOut,
    handleJudgeAuth
  }
})
