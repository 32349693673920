import { ref } from 'vue'
import { defineStore } from 'pinia'

export const tagsViewStore = defineStore('tagsView', () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cachedViews = ref<any>([])

  return {
    cachedViews
  }
})
