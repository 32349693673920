<template>
  <div class="w-full menu-box flex flex-row justify-between items-center h-header min-w-[768px]">
    <div v-if="isHideSlide">
      <div class="h-header ml-[8px] inline-flex flex-nowrap items-center">
        <div class="mr-sm">
          <img
            @click.stop="router.replace({ path: '/index' })"
            class="h-[35px] cursor-pointer"
            :src="IV" />
        </div>
        <i
          class="iconfont icon-caidanzhedie text-contrast-title"
          @click="slideStore().setIsHideDrawer(!isHideDrawer)"
          :class="[isHideSlide ? '' : 'active']"></i>
      </div>
    </div>
    <div class="overflow-hidden ml-[20px] bbb w-full flex">
      <div
        class="overflow-x-scroll container"
        :style="{ width: menuWidth + 'px' }">
        <div>
          <tab-bar
            @tagTotalWidth="tagTotalWidth"
            v-if="!setting.isShowTopbar"></tab-bar>
        </div>
      </div>
      <div
        v-if="tagBoxWidth > menuWidth"
        @click="opneMoreTag"
        style="box-sizing: 0 0 10px rgba(0, 0, 0, 0.5)"
        class="bg-white h-full px-xxs pt-xxs cursor-pointer">
        <iconfont name="icon-icon_more"></iconfont>
      </div>
    </div>
    <div class="pr-md justify-items-end flex flex-row flex-nowrap items-center whitespace-nowrap">
      <div
        class="text-word-subtitle mr-[12px] cursor-pointer"
        @click="changeMobile">
        <i class="iconfont icon-shouji text-20"></i>
      </div>
      <div
        class="mr-[12px] text-word-subtitle relative cursor-pointer"
        @click="touchMsg">
        <i class="iconfont icon-icon_bell font-medium text-20"></i>
        <div
          v-if="noReadMsg"
          class="absolute w-[5px] h-[5px] bg-error-click rounded-50per top-[1px] right-[1px] border border-solid border-white"></div>
      </div>
      <a-dropdown
        trigger="hover"
        @select="handleSelect">
        <div class="text-contrast-subtitle cursor-pointer mr-normal flex flex-row justify-end items-center">
          <img
            class="w-lg h-lg rounded-50per"
            :src="userInfo.avatar" />
          <div
            v-if="userInfo.userInfo"
            class="ml-xs font-medium hover:text-primary-normal">
            {{ userInfo.userInfo.userName }}
          </div>
        </div>

        <template #content>
          <router-link to="/userInfo/person">
            <a-doption value="2">个人中心</a-doption>
          </router-link>
          <a-doption value="3">修改密码<i style="opacity: 0; width: 50px"></i></a-doption>
          <a-doption value="1">退出登录</a-doption>
          <a-doption value="4">版本查看</a-doption>
        </template>
      </a-dropdown>
    </div>
    <a-modal
      v-model:visible="EditPasswordVisible"
      @ok="handleOk"
      @cancel="handleCancel"
      title-align="start"
      width="600px"
      :mask-closable="false"
      :footer="false">
      <template #title>
        <div class="text-word-subtitle"><span class="text-word-title">修改密码</span>&nbsp;<span class="text-12 text-word-disabled">(修改完后需重新登录)</span></div>
      </template>
      <div class="px-[20px] pt-lg pb-md">
        <div class="p-sm">
          <a-form
            ref="formRef"
            :model="userForm"
            label-align="left">
            <a-form-item
              field="oldPassword"
              label="旧密码"
              :rules="[{ required: true, message: '旧密码必填' }]"
              :validate-trigger="['blur']">
              <a-input-password
                onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                v-model="userForm.oldPassword"
                placeholder="请输入旧密码" />
            </a-form-item>

            <div class="text-12 mt-xxs text-word-disabled pl-xs mb-sm pt-xxs border-0 border-t-[1px] border-solid border-bg-hover">
              新密码长度16-32位，需包含数字、大小写字母、符号
            </div>
            <a-form-item
              field="newPassword"
              label="新密码"
              :rules="[
                { required: true, message: '新密码必填' },
                { minLength: 16, maxLength: 32, message: '密码长度16-32位哦' },
                {
                  match: /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9A-Za-z~!@#$%^&*._?]{16,32}$/,
                  message: '密码格式不正确'
                }
              ]"
              :validate-trigger="['blur']">
              <a-input-password
                onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                v-model="userForm.newPassword"
                :max-length="32"
                placeholder="请输入新密码" />
            </a-form-item>
            <a-form-item
              field="alignPassword"
              label="再次确认"
              :rules="[
                { required: true, message: '新密码必填' },
                { minLength: 16, maxLength: 32, message: '密码长度16-32位哦' },
                {
                  match: /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9A-Za-z~!@#$%^&*._?]{16,32}$/,
                  message: '密码格式不正确'
                }
              ]"
              :validate-trigger="['blur']">
              <a-input-password
                v-model="userForm.alignPassword"
                :max-length="32"
                onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                placeholder="请输入新密码" />
            </a-form-item>
          </a-form>
        </div>
        <div class="flex justify-end mt-md">
          <a-button
            class="mr-sm default-btn"
            type="outline"
            @click="handleCancel"
            >取消</a-button
          >
          <a-button
            type="primary"
            @click="handleOk"
            >确定</a-button
          >
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import TabBar from './/tab-bar.vue'
import { ref, getCurrentInstance, onMounted, computed, onUnmounted } from 'vue'
import { userInfoStore } from '@/store/userInfo'
import { permissionStore } from '@/store/permission'
import { resetpwd } from '@/api/mineInfo'
import { useRouter } from 'vue-router'
import { Message, Modal } from '@arco-design/web-vue'
import { removeToken } from '@/utils/auth'
// import { updateMinePwd } from '@/api/mineInfo'
import { settingStore } from '@/store/setting'
import { slideStore } from '@/store/slide'

import IV from '@/assets/images/logo_word.png'
// import { getTotal } from '@/api/usermsg'

const router = useRouter()
const userInfo = userInfoStore()
const permission = permissionStore()
const setting = settingStore()
const userData = userInfo.userInfo
console.log(userInfo, '0000000000')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { proxy }: any = getCurrentInstance()
const EditPasswordVisible = ref<boolean>(false)
const userForm = ref({
  oldPassword: '',
  newPassword: '',
  alignPassword: ''
})
const formRef = ref()
const noReadMsg = ref(0)
const menuWidth = ref<number>(1800)
let container = ref()
const tagBoxWidth = ref<number>(0)
const isDown = ref<boolean>(false) // 是否按下鼠标
const startX = ref<number>(0) // 鼠标起始位置
const scrollLeft = ref<number>(0) // 滚动条位置
const x = ref()
const walk = ref()
const detail = ref()
const moveForwardStep = ref<number>(1) // 定义滚动方向，
const moveBackStep = ref<number>(-1)
const step = ref<number>(0) // 定义滚动距离
/**
  @function loginOutFunc 退出登录事件
  @function handleSelect 选择事件
  @function handleOk     修改密码事件
  @function handleCancel 取消修改事件
  @function touchMsg     点击消息事件
*/

let isHideSlide = computed(() => {
  return slideStore().isHideSlide
})
let isHideDrawer = computed(() => {
  return slideStore().isHideDrawer
})
// const getMsgNumber = async () => {
//   try {
//     let res = await getTotal()
//     if (res?.code == 200) {
//       noReadMsg.value = res.data
//     } else {
//       Message.error(res?.msg || '获取未读消息数量失败')
//     }
//   } catch (error) {
//     console.log(error)
//   }
// }
const loginOutFunc = () => {
  Modal.open({
    title: '退出登录',
    content: '是否确定退出登录？',
    closable: true,
    onOk() {
      userInfo.loginOut().then((res: boolean) => {
        removeToken()
        permission.routes = []
        permission.expandRoutes = []
        permission.fastEntryRoutes = []
        permission.tabRoutes = []
        permission.mobileFastEntryRoutes = []
        permission.dynamicRoutes = []
        permission.defaultRoutes = []
        permission.topbarRouters = []
        permission.sidebarRouters = []
        if (res) {
          router.replace({ path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE })
        }
      })
    },

    okText: '退出登录',
    cancelText: '取消退出'
  })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleSelect = (value: string | number | Record<string, any> | undefined) => {
  switch (value) {
    case '1':
      loginOutFunc()
      break
    case '3':
      EditPasswordVisible.value = true
      break
    case '4':
      window.open('/version.html', '_blank')
      break
  }
}

const handleOk = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef.value.validate(async (val: any) => {
    if (val == undefined) {
      if (userForm.value.newPassword == userForm.value.alignPassword) {
        try {
          console.log(userData)

          const res2 = await resetpwd({
            oldPassword: userForm.value.oldPassword,
            password: userForm.value.newPassword,
            userName: userData.userName
          })
          if (res2.code === 200) {
            proxy.modal.success({
              title: '修改成功！',
              content: '修改密码成功，是否重新登录？',
              onOk() {
                userInfo.loginOut().then(() => {
                  router.replace({
                    path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE
                  })
                })
              }
            })
          } else {
            Message.error(`${res2.msg}`)
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        Message.warning('确认密码与密码不一致')
      }
    }
  })
}

const changeMobile = () => {
  proxy.modal.warning({
    title: '切换到手机端',
    content: '是否确认切换到手机端？',
    onOk() {
      window.open(window.location.protocol + '//' + window.location.hostname + ':' + (window.VITE_APP_MOBILE_PORT || import.meta.env.VITE_APP_MOBILE_PORT), '_black')
    }
  })
}
const handleCancel = () => {
  EditPasswordVisible.value = false
  userForm.value = {
    oldPassword: '',
    newPassword: '',
    alignPassword: ''
  }
}

const opneMoreTag = () => {
  container.value.scrollLeft = 2000000
}
const tagTotalWidth = (val: number) => {
  tagBoxWidth.value = val
}
const emit = defineEmits(['openMsg'])
// getMsgNumber()

const touchMsg = () => {
  emit('openMsg')
}

onMounted(() => {
  let box = document.querySelector('.bbb')
  if (box) {
    menuWidth.value = box.clientWidth
  }
  container.value = document.querySelector('.container') // 获取容器元素
  if (container.value) {
    isDown.value = false
    container.value.addEventListener('mousedown', (e: MouseEvent) => {
      isDown.value = true
      startX.value = e.pageX - container.value.offsetLeft
      scrollLeft.value = container.value.scrollLeft
    })
    window.addEventListener('mouseup', () => {
      isDown.value = false
    })
    container.value.addEventListener('mousemove', (e: MouseEvent) => {
      if (!isDown.value) return
      e.preventDefault()
      x.value = e.pageX - container.value.offsetLeft
      walk.value = (x.value - startX.value) * 2
      container.value.scrollLeft = scrollLeft.value - walk.value
    })
    // 滚动事件函数
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (event: WheelEvent | any) => {
      // 获取滚动方向
      detail.value = event.wheelDelta || event.detail
      // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
      if (detail.value < 0) {
        step.value = moveForwardStep.value * 50
      } else {
        step.value = moveBackStep.value * 50
      }
      // 对需要滚动的元素进行滚动操作
      container.value.scrollLeft += step.value
    }
    container.value.addEventListener('mousewheel', handler, false)
  }
})
onUnmounted(() => {
  container.value.removeEventListener('mousedown', (e: MouseEvent) => {
    isDown.value = true
    startX.value = e.pageX - container.value.offsetLeft
    scrollLeft.value = container.value.scrollLeft
  })
  window.removeEventListener('mouseup', () => {
    isDown.value = false
  })
  container.value.removeEventListener('mousemove', (e: MouseEvent) => {
    if (!isDown.value) return
    e.preventDefault()
    x.value = e.pageX - container.value.offsetLeft
    walk.value = (x.value - startX.value) * 2
    container.value.scrollLeft = scrollLeft.value - walk.value
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  container.value.removeEventListener('mousewheel', (event: WheelEvent | any) => {
    detail.value = event.wheelDelta || event.detail
    if (detail.value < 0) {
      step.value = moveForwardStep.value * 50
    } else {
      step.value = moveBackStep.value * 50
    }
    container.value.scrollLeft += step.value
  })
})
userInfo.getUserInfo()
</script>
<style lang="less" scoped>
.menu-box {
  box-shadow: 0 4px 10px 0 rgb(78 89 105 / 6%);
  .defaultbtn {
    border: none;
  }
  .container {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
