import { ref } from 'vue'
import { defineStore } from 'pinia'

export const settingStore = defineStore('setting', () => {
  const theme = ref<'light' | 'dark' | undefined>('light')
  const isShowSidebar = ref<boolean>(true)
  const isShowTopbar = ref<boolean>(false)
  const tableBorder = ref<boolean>(false)
  const tableStripe = ref<boolean>(false)
  const tableHover = ref<boolean>(true)
  const scroll = ref({ minWidth: 800, x: '100%', y: 'calc(100vh - 173px)' })
  const lebel2scroll = ref({ minWidth: 400 })
  const dateFormat = ref('YYYY/MM/DD HH:mm:ss')
  return {
    theme,
    isShowSidebar,
    isShowTopbar,
    tableBorder,
    tableStripe,
    tableHover,
    dateFormat,
    scroll,
    lebel2scroll
  }
})
