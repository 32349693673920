import { App } from 'vue'
import SvgIcon from './index.vue'
import 'virtual:svg-icons-register'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const svgIconPlugin: any = {
  name: '',
  install(app: App): void {
    // 全局挂载
    app.component('svg-icon', SvgIcon)
  }
}

export default svgIconPlugin
