import { createApp } from 'vue'
import 'nprogress/nprogress.css'
import { createPinia } from 'pinia'
import router from '@/router/index'
import '@/router/permission'
import dayjs from '@/plugins/day'
import SvgIcon from '@/component/svg-icon/index'
import 'virtual:svg-icons-register'
import '@/assets/styles/index.less'
import '@/assets/iconfont/iconfont.js'
import Cookies from 'js-cookie'
import { useDict } from '@/utils/dict'
import directive from '@/directives' // directive
import App from './App.vue'
import modal from '@/utils/modal'
import iconfont from '@/component/iconfont/index'
// import '@/utils/mapvgl'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cApp: any = createApp(App)

// 全局方法挂载
cApp.config.globalProperties.useDict = useDict
cApp.config.globalProperties.modal = modal

// cApp.config.globalProperties.useDict = useDict

cApp.config.globalProperties.dayjs = dayjs
cApp.config.globalProperties.cookie = Cookies

cApp.use(router).use(createPinia()).use(iconfont).use(SvgIcon)

directive(cApp)

cApp.mount('#app')
