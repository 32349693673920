import { ref, toRefs } from 'vue'
import { useDictStore } from '@/store/dict'
import { getDicts } from '@/api/system/dict/data'

/**
 * 获取字典数据
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDict(...args: any[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = ref<any>({})
  return (() => {
    args.forEach((dictType) => {
      res.value[dictType] = []
      const dicts = useDictStore().getDict(dictType)
      if (dicts) {
        res.value[dictType] = dicts
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getDicts(dictType).then((resp: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.value[dictType] = resp.data.map((p: any) => ({
            label: p.dictLabel,
            value: p.dictValue,
            elTagType: p.listClass,
            elTagClass: p.cssClass
          }))
          useDictStore().setDict(dictType, res.value[dictType])
        })
      }
    })
    return toRefs(res.value)
  })()
}
